import { graphql } from 'gatsby';
import Component from 'src/templates/AllTalksPage';
export default Component;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort:   { fields: [frontmatter___date___published], order: DESC }
      filter: { fields: { type: { eq: "talks" } } }
    ) { ...TalkFragment }
  }

  fragment TalkFragment on MarkdownRemarkConnection {
    edges {
      node {
        frontmatter {
          date { published }
          title
          description
          cover {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.77777778
                width: 1000
                quality: 100
              )
            }
          }
        }
        fields { slug }
      }
    }
  }
`;

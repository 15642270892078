import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import URLs from 'src/utils/urls';
import * as styles from './AllTalksPage.module.sass';


const AllTalksPage = ({ data, location }) => {
  const talks = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <SEO
        title="All Talks"
        location={location}
      />

      <h1 className={styles.title}>
        Talks
      </h1>

      <main className={styles.container}>
        <ul className={styles.talkList}>
          {talks.map(t => renderTalk(t))}
        </ul>
      </main>
    </Layout>
  );
};


const renderTalk = ({ node: { fields, frontmatter }}) => {
  const { title, cover } = frontmatter;
  const { slug } = fields;

  return (
    <li key={slug} className={styles.talk}>
      <Link to={URLs.talkPath(slug)}>
        <GatsbyImage
          image={cover.childImageSharp.gatsbyImageData}
          alt={title}
          className={styles.cover}
        />
      </Link>
    </li>
  );
};


export default AllTalksPage;
